import React, { useState } from 'react'
import RecipeCard from '../RecipeCard'
import Checkmark from '../../../images/svgs/checkmark-green.svg'
import scrollTo from 'gatsby-plugin-smoothscroll'

const toggleElement = (array, element) => {
  const elementIndex = array.indexOf(element)

  let newElements = []
  if (elementIndex == -1) {
    newElements = array.concat([element])
  } else {
    newElements = array.filter(item => item !== element)
  }

  return newElements
}

const SelectRecipes = ({ recipes, setRecipes, scrollable }) => {
  const [selectedRecipes, setSelectedRecipes] = useState([])

  const toggleSelectedRecipe = (recipe) => {
    const newRecipes = toggleElement(selectedRecipes, recipe.slug)

    setRecipes(newRecipes)
    setSelectedRecipes(newRecipes)
  }

  return (
    <div className="container pt-5" id="pick-recipes">
      <div className="row">
        <div className="col-12">
          <h2 className="display-1 font-weight-bold">Get Started</h2>
        </div>
      </div >
      <div className="row mt-3">
        <div className="col-12">
          <h2 className="font-weight-bold">Step 1 - Pick a few recipes</h2>
          {selectedRecipes.length > 0 && <p className="mb-0"><Checkmark className="w25p" />
            {'\u00A0'}Now <a className="cursor-pointer" onClick={() => scrollTo('#pick-ingredients', 'start')}>shop your ingredients</a
            ></p>}
          {selectedRecipes.length == 0 && <p className="mb-0">Tap a recipe to shop its ingredients</p>}
        </div>
      </div>
      <div className={`row pt-2 px-3 px-md-0 ${scrollable ? 'scroll-horizontal' : ''}`}>
        {recipes.map((recipe) => (
          <div className={`${scrollable ? 'col-7 col-sm-5' : 'col-6 col-sm-4'} pb-4 px-md-4 px-sm-2 px-1 pt-4`} key={recipe.slug}>
            <RecipeCard recipe={recipe} selectRecipe={toggleSelectedRecipe} selected={selectedRecipes.indexOf(recipe.slug) != -1} />
          </div>
        ))}
      </div>
    </div >
  )
}

export default SelectRecipes
